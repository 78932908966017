import Stack from "@ds/Stack/Stack";
import Clickable from "../Clickable/Clickable";
import Group from "@ds/Group/Group";
import { BaseProps } from "@interfaces/BaseProps";

import "./GameCard.scss";

export type GameCardFields = {
  img: string;
  title: string;
  description: string;
  isDisabled: boolean;
  btnText: string;
  video?: string;
};

export type GameCardProps = GameCardFields & { isMobile: boolean } & BaseProps;

const GameCard = ({ btnText, description, img, isDisabled, title, isMobile, video, ...rest }: GameCardProps) => {
  const disabledClass = `${isDisabled ? "disabled" : ""}`;
  const componentClassName = `game-card ${isMobile ? "mobile" : ""} ${rest.className}`;

  return (
    <div className={componentClassName} style={rest.style}>
      <Stack fullWidth>
        <div className="img-wrapper">
          <img src={img} className="img" />
          {!!video && !isMobile && (
            <video
              playsInline
              muted
              autoPlay
              loop
              width={"100%"}
              height={"auto"}
              className="gif"
              style={{ minHeight: "100%" }}
            >
              <source src={video} />
            </video>
          )}
        </div>
        <Stack className={`body ${disabledClass}`}>
          <h4 className={`title white-0 mb-xs-1 ${disabledClass} capitalize`} title={title}>
            {title}
          </h4>
          <p
            className={`description b2 white-0 op-06 mb-xs-2 ${disabledClass} capitalize t-xs-l`}
            title={description}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {description}
          </p>
          <Clickable
            variant={"primary"}
            coloring={"green"}
            size={"small"}
            isDisabled={isDisabled}
            className="card-button"
          >
            <Group fullSize justifyContent="center" alignItems="center">
              <p className="butt-3 black-0 capitalize-each">{btnText}</p>
            </Group>
          </Clickable>
        </Stack>
      </Stack>
    </div>
  );
};

export default GameCard;
