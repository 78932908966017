import { ReactNode } from "react";

import Container from "src/components/Container/Container";
import Stack from "@ds/Stack/Stack";
import Group from "@ds/Group/Group";
import Button from "@ds/Button/Button";
import LinkLike from "src/components/LinkLike/LinkLike";

import { copyToClipboard } from "@utils/utils";
import { ARBISCAN_CONTRACT_ADDRESS } from "@constants/constants";
import { Coloring } from "@interfaces/Coloring";

import "./Links.scss";
import useIsMobile from "@hooks/useIsMobile";

interface CardProps {
  coloring: Coloring;
  img: string;
  title: string;
  buttonContent: ReactNode;
  buttonAction(): void;
  isDisabled: boolean;
}

const Card = ({ img, title, buttonAction, isDisabled, buttonContent, coloring }: CardProps) => {
  const isMobile = useIsMobile("md");

  return (
    <Stack
      justifyContent="flex-start"
      alignItems="center"
      className="w-xs-100 w-md-mc card"
      style={{ ["--coloring" as any]: `var(--${coloring})` }}
    >
      <img src={img} className="mb-xs-1" />
      <p className="b1 capitalize white-0 t-xs-c card-title mb-xs-2">{title}</p>
      <Button as="button" disabled={isDisabled} className="w-xs-100" onClick={buttonAction}>
        <Group fullWidth justifyContent="center" alignItems="center">
          <LinkLike
            size={"medium"}
            isDisabled={isDisabled}
            coloring={coloring}
            className="w-xs-100 w-md-mc"
            style={{ width: isMobile ? "100%" : "260px" }}
          >
            {buttonContent}
          </LinkLike>
        </Group>
      </Button>
    </Stack>
  );
};

const links: CardProps[] = [
  {
    img: "/images/graphics/metamask-circle-97.png",
    title: "Add our token to metamask",
    buttonContent: (
      <Group fullSize colGap={4} justifyContent="center" alignItems="center">
        <p>Add G2E token</p>
        <img src="/images/icons/arrow-right-metamask-14.svg" />
      </Group>
    ),
    buttonAction: () => console.log("metamask"),
    isDisabled: false,
    coloring: "metamask"
  },
  {
    img: "/images/graphics/arbiscan-circle-97.png",
    title: "Check the contract on arbiscan.io",
    buttonContent: (
      <Group fullSize colGap={4} justifyContent="center" alignItems="center" title={ARBISCAN_CONTRACT_ADDRESS}>
        <p className="ellipsis">{ARBISCAN_CONTRACT_ADDRESS}</p>
        {/* <img src="/images/icons/copy-arbiscan-13.svg" title={"Copy"} /> */}
      </Group>
    ),
    buttonAction: () => {
      // copyToClipboard(ARBISCAN_CONTRACT_ADDRESS)
    },
    isDisabled: false,
    coloring: "arbiscan"
  },
  {
    img: "/images/graphics/uniswap-circle-97.png",
    title: "Buy / Sell on UNISWAP",
    buttonContent: (
      <Group fullSize colGap={4} justifyContent="center" alignItems="center">
        <p>Add G2E token</p>
        <img src="/images/icons/chart-pink-16.svg" />
      </Group>
    ),
    buttonAction: () => console.log("uniswap"),
    isDisabled: false,
    coloring: "pink"
  }
];

const Links = () => {
  return (
    <Container className="links" maxWidth="100%" withPadding>
      <Container className="pt-xs-20">
        <div className="disabled token-section relative">
          <Group
            fullWidth
            justifyContent="space-evenly"
            alignItems="flex-start"
            // colGap={20}
            rowGap={40}
            className="grid op-05"
            style={{ maxWidth: "1110px", margin: "auto" }}
          >
            {links.map((link, id) => (
              <Card {...link} key={id} />
            ))}
          </Group>
          <h5 className="title w-xs-100 t-xs-c">Token section available in July 2024</h5>
        </div>
      </Container>
    </Container>
  );
};

export default Links;
